<template>
  <v-row>
    <v-col class="col-12">
      <h2>Organization Name</h2>
      <v-card style="margin-top: 20px">
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mt-6">
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="name"
                  outlined
                  label="Organizaton Name"
                  :rules="[validators.required]"
                  placeholder="Organizaton Name"
                  hide-details="auto"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="email"
                  outlined
                  label="Email"
                  :rules="[validators.emailValidator]"
                  placeholder="Email"
                  hide-details="auto"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="website" outlined label="Website" placeholder="Website" hide-details="auto" :rules="[validators.urlValidator]" />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="phone" outlined label="Phone" placeholder="Phone" hide-details="auto" />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="service" outlined label="Service" placeholder="Service" hide-details="auto" />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="address"
                  outlined
                  label="Address"
                  :rules="[validators.required]"
                  placeholder="Address"
                  hide-details="auto"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field v-model="address2" outlined label="Address2" placeholder="Address2" hide-details="auto" />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="city"
                  outlined
                  label="City"
                  :rules="[validators.required]"
                  placeholder="City"
                  hide-details="auto"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="state"
                  outlined
                  label="State"
                  :rules="[validators.required]"
                  placeholder="State"
                  hide-details="auto"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="zip"
                  outlined
                  label="Zip"
                  :rules="[validators.required, validators.integerValidator]"
                  placeholder="Zip"
                  hide-details="auto"
                />
              </v-col>
              <v-col md="6" cols="12">
                <v-select
                  v-model="country"
                  :items="countries"
                  label="Select Country"
                  item-text="name"
                  item-value="code"
                  outlined
                  :rules="[validators.required]"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-alert v-if="errors" class="mt-4" color="error" text>
            <p class="font-weight-semibold mb-1">Messages</p>
            <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
              {{ error }}
            </p>
          </v-alert>
        </v-card-text>
        <v-card-actions>

          <v-btn color="primary" class="btn-submit" @click="submit" :disabled="loading">
              <span v-if="loading === false">Submit</span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 5px"></v-progress-circular>
                <span>Sending request...</span>
              </span>
            </v-btn>

        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'
import countries from '@/const/countries'

export default {
  data() {
    return {
      loading: false,
      errors: null,
      name: '',
      email: '',
      website: '',
      phone: '',
      service: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      countries,
      validators: {
        required,
        emailValidator,
        urlValidator,
        integerValidator,
      },
    }
  },
  methods: {
    ...mapActions({
      createClientAccount: 'requests/createClientAccount',
      setSnackbar: 'snackbar/set',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const formData = {
          name: this.name.trim(),
          email: this.email.trim(),
          website: this.website.trim(),
          service: this.service.trim(),
          phone: this.phone.trim(),
          address: this.address.trim(),
          address2: this.address2.trim(),
          city: this.city.trim(),
          state: this.state.trim(),
          zip: this.zip.trim(),
          country: this.country.trim(),
        }

        this.loading = true,
        this.createClientAccount(formData)
          .then(resp => {
            if (resp && resp.data) {
              if (resp.data.status == 'ok') {
                this.setSnackbar({
                  status: true,
                  text: 'The request has been sent.',
                  color: 'primary',
                })

                this.errors = null
                this.$refs.form.reset();
              }
            }
          })
          .catch(error => {
            console.log(error)
            if (error.response.data && error.response.data.status === 'error') {
              this.errors = error.response.data.errors
            }

            this.setSnackbar({
              status: true,
              text: 'Failure to process request.',
              color: 'error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>

